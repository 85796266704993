import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import server from '../api/server';
import history from '../history';
import AdminContainer from './AdminContainer';

export class PrioHosting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hostingData: [], // Will store the priority hosting data
            sortField: null,
            sortDirection: 'asc',
            coupons: [],
            name: "",
            percentage: 10,
            selectedUserId: null,
            selectedBotId: null,
            newSubscriptionId: "",
            convertModalDisabled: false,
            convertToNormal: false,
            newBotId: '',
            addPriorityDisabled: false
        };
    }

    componentDidMount() {
        if (this.props.user.admin_perms.coupons != true) {
            history.push('/');
        }

        // Fetch hosting data
        this.fetchHostingData();
    }

    fetchHostingData = async () => {

        try {
            const response = await server.get("/admin/hosting-data");
            if (response.data.success) {
                this.setState({ hostingData: response.data.hostingData });
            }
        } catch (err) {
            console.error("Failed to fetch hosting data:", err);
        }



        // Comment out the API call for now

    };

    handleSort = (field) => {
        const direction = this.state.sortField === field && this.state.sortDirection === 'asc' ? 'desc' : 'asc';
        this.setState({
            sortField: field,
            sortDirection: direction,
            hostingData: [...this.state.hostingData].sort((a, b) => {
                if (field === 'serverCount') {
                    return direction === 'asc' ? a[field] - b[field] : b[field] - a[field];
                }
                return direction === 'asc'
                    ? String(a[field]).localeCompare(String(b[field]))
                    : String(b[field]).localeCompare(String(a[field]));
            })
        });
    };

    handleStatusChange = async (botId, newStatus) => {
        try {
            const response = await server.post("/admin/update-hosting-status", {
                bot_id: botId,
                status: newStatus
            });

            if (response.data.success) {
                this.setState(prevState => ({
                    hostingData: prevState.hostingData.map(item =>
                        item.bot_id === botId ? { ...item, priority_status: newStatus } : item
                    )
                }));
            }
        } catch (err) {
            console.error("Failed to update status:", err);
        }
    };

    getStatusColor = (status) => {
        return 'text-white';
    };

    handleConvertSubmit = async (e) => {
        e.preventDefault();
        this.setState({ convertModalDisabled: true });

        try {
            const response = await server.post("/admin/convert-to-priority", {
                bot_id: this.state.selectedBotId,
                sub_id: this.state.newSubscriptionId,
                convertToNormal: this.state.convertToNormal
            });

            if (response.data.success) {
                // Update the local state with the new status
                this.setState(prevState => ({
                    hostingData: prevState.hostingData.map(item =>
                        item.bot_id === this.state.selectedBotId
                            ? {
                                ...item,
                                priority: this.state.convertToNormal ? false : true,
                                priority_status: this.state.convertToNormal ? '' : 'priority',
                                priority_sub_id: this.state.convertToNormal ? '' : this.state.newSubscriptionId
                            }
                            : item
                    ),
                    newSubscriptionId: "",
                    convertModalDisabled: false,
                    selectedBotId: null,
                    convertToNormal: false
                }));
                window.$('#convertHostingModal').modal('hide');
            }
        } catch (err) {
            console.error("Failed to convert hosting status:", err);
            this.setState({ convertModalDisabled: false });
        }
    };

    renderConvertModal = () => {
        return (
            <div id="convertHostingModal" className="modal fade" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                {this.state.convertToNormal ? 'Convert to Normal Hosting' : 'Convert to Priority Hosting'}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.handleConvertSubmit}>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label className="form-label">User ID</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={this.state.selectedUserId || ''}
                                        disabled
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Bot ID</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={this.state.selectedBotId || ''}
                                        disabled
                                    />
                                </div>
                                {!this.state.convertToNormal && (
                                    <div className="mb-3">
                                        <label className="form-label">Subscription ID</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={this.state.newSubscriptionId}
                                            onChange={(e) => this.setState({ newSubscriptionId: e.target.value })}
                                            placeholder="Enter subscription ID"
                                            required
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                                <button
                                    type="submit"
                                    className={`btn ${this.state.convertToNormal ? 'btn-danger' : 'btn-primary'}`}
                                    disabled={this.state.convertModalDisabled}
                                >
                                    {this.state.convertToNormal ? 'Convert to Normal' : 'Convert to Priority'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    };

    renderAddPriorityModal = () => {
        return (
            <div id="addPriorityModal" className="modal fade" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Priority Bot</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.handleAddPriority}>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label className="form-label">Bot ID</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={this.state.newBotId || ''}
                                        onChange={(e) => this.setState({ newBotId: e.target.value })}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Subscription ID</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={this.state.newSubscriptionId || ''}
                                        onChange={(e) => this.setState({ newSubscriptionId: e.target.value })}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={this.state.addPriorityDisabled}
                                >
                                    Add Priority Bot
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    };

    handleAddPriority = async (e) => {
        e.preventDefault();
        this.setState({ addPriorityDisabled: true });

        try {
            const response = await server.post("/admin/convert-to-priority", {
                bot_id: this.state.newBotId,
                sub_id: this.state.newSubscriptionId,
                convertToNormal: false
            });

            if (response.data.success) {
                // Refresh the hosting data to get the updated list
                await this.fetchHostingData();

                // Clear the form
                this.setState({
                    newBotId: '',
                    newSubscriptionId: '',
                    addPriorityDisabled: false
                });
                window.$('#addPriorityModal').modal('hide');
            }
        } catch (err) {
            console.error("Failed to add priority bot:", err);
            this.setState({ addPriorityDisabled: false });
        }
    };

    renderHostingTable = () => {
        return (
            <table className="table table-dark table-striped table-wrap">
                <thead>
                    <tr>
                        <th scope="col">User ID</th>
                        <th scope="col">Bot ID</th>
                        <th scope="col" onClick={() => this.handleSort('serverCount')} style={{ cursor: 'pointer' }}>
                            Amount of Servers {this.state.sortField === 'serverCount' && (this.state.sortDirection === 'asc' ? '↑' : '↓')}
                        </th>
                        <th scope="col" onClick={() => this.handleSort('status')} style={{ cursor: 'pointer' }}>
                            Status {this.state.sortField === 'status' && (this.state.sortDirection === 'asc' ? '↑' : '↓')}
                        </th>
                        <th scope="col">Subscription ID</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.hostingData.map(item => (
                        <tr key={item.user_id}>
                            <td>{item.user_id}</td>
                            <td>{item.bot_id}</td>
                            <td>{item.server_count}</td>
                            <td>
                                <select
                                    className={`form-select form-select-sm ${this.getStatusColor(item.priority_status)}`}
                                    value={item.priority ? 'priority' : (item.priority_status || '')}
                                    onChange={(e) => this.handleStatusChange(item.bot_id, e.target.value)}
                                    style={{
                                        backgroundColor: item.priority_status === 'contacted' ? '#198754' :
                                            item.priority_status === 'declined' ? '#dc3545' :
                                                item.priority ? '#0d6efd' :
                                                    'transparent',
                                        color: 'white'
                                    }}
                                >
                                    <option value="" style={{ color: 'inherit' }}>No Status</option>
                                    {item.priority && (
                                        <option value="priority" style={{ backgroundColor: '#0d6efd', color: 'white' }}>Priority</option>
                                    )}
                                    <option value="contacted" style={{ backgroundColor: '#198754', color: 'white' }}>Contacted</option>
                                    <option value="declined" style={{ backgroundColor: '#dc3545', color: 'white' }}>Declined</option>
                                </select>
                            </td>
                            <td>{item.priority_sub_id}</td>
                            <td>
                                <button
                                    className={`btn btn-sm ${item.priority === true ? 'btn-danger' : 'btn-primary'}`}
                                    onClick={() => {
                                        this.setState({
                                            selectedUserId: item.user_id,
                                            selectedBotId: item.bot_id,
                                            convertToNormal: item.priority === true
                                        });
                                        window.$('#convertHostingModal').modal('show');
                                    }}
                                >
                                    {item.priority === true ? 'Convert to Normal' : 'Convert to Priority'}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    render() {
        const priorityCount = this.state.hostingData.filter(item => item.priority === true).length;

        return (
            <AdminContainer title="Priority Hosting">
                {this.renderConvertModal()}
                {this.renderAddPriorityModal()}
                <div id="addCouponModal" class="modal fade" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true" style={{ display: "none" }}>
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="myModalLabel">Add Coupon Code</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                            </div>
                            <form onSubmit={async (e) => {
                                e.preventDefault();

                                this.setState({ disabled: true });
                                var response = await server.post("/admin/addcoupon", this.state);

                                if (response && response.data && response.data.success == true) {
                                    var coupons = [...this.state.coupons];
                                    coupons.push(response.data.coupon);
                                    this.setState({ coupons: coupons });
                                    // this.setState({
                                    //     id: "",
                                    //     number: 1,
                                    //     reason: "",
                                    //     email: "",
                                    //     type: "",
                                    //     disabled: false
                                    // })
                                    window.$('#addCouponModal').modal('toggle');
                                    // this.props.success(response.data.user);

                                }
                            }}>
                                <div class="modal-body">
                                    <div className='mb-3'>
                                        <label for="subscription_id" class="form-label">Coupon Name</label>
                                        <input placholder="TOM" value={this.state.name} onChange={(e) => {
                                            this.setState({ name: e.target.value });
                                        }} required type="text" class="form-control" id="name" />

                                    </div>

                                    <div className='mb-3'>
                                        <label for="sub_email" class="form-label">Discount Percentage</label>
                                        <input placeholder="50" value={this.state.percentage} onChange={(e) => {
                                            this.setState({ percentage: e.target.value });
                                        }} required type="number" class="form-control" id="sub_email" />
                                        <div id="passwordHelpBlock" class="form-text">
                                            Will be applied to all subscriptions.
                                        </div>
                                    </div>

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                                    <button disabled={this.state.disabled} type="submit" class="btn btn-primary ">Add Tokens</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className='col-lg-12 col-md-12'>
                        <div className="justify-content-between d-flex align-items-center mt-3 mb-4">
                            <div>
                                <h5 className="mb-0 pb-1">Priority Hosting Management</h5>
                                <small className="text-muted">Total Priority Bots: {priorityCount}</small>
                            </div>
                            <button
                                className="btn btn-primary"
                                onClick={() => window.$('#addPriorityModal').modal('show')}
                            >
                                Add Priority Bot
                            </button>
                        </div>
                        {this.renderHostingTable()}
                    </div>
                </div>
            </AdminContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PrioHosting);